<template>
  <div class="foodpage-wrapper">      
    <div v-show="!loading" class="custom-container" style="background: transparent;">
      <div class="empty-tab">
        <div style="width: 100%;height: 80%;border-radius: 30px;overflow: hidden;padding: 0;margin-top: 100px">
          <lottie-player src="https://lottie.host/c95d32bb-b190-45b5-b21a-f8a0bcd0ecf0/pfi9xYsn9t.json" 
                     background="transparent" speed="1" style="width: 100%" loop autoplay>
          </lottie-player>
        </div>

        <h2 class="text-dark coomingsoon-title">Order Placed</h2>
        <h3 class="mt-3 text-danger" v-show="message !== 'Cart Submitted'">{{ message }}</h3>
        <h5 class="mt-3">Please Wait, Your Foods is Being Prepared!</h5>

        <button class="btn login-btn w-100 mt-5" style="background-color: #A9C932;" role="button" @click="setUpdate">
            ADD MORE
        </button>
        <button class="btn login-btn w-100 mb-3" role="button">
            REQUEST BILL
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'SubmittedView',
    props: ['message'],
    data(){
      return {
        loading: true,
      }
    },
    mounted(){
      this.loading = true
      const decode = atob(this.$route.query.scr);
      const arrData = decode.split(";");
      this.servers = 'http://' + arrData[0] + '/api/web';

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    methods: {
      setUpdate(){
        axios.post(this.servers + '/update_submit/' + this.$route.query.scr)
        .then(() => {
            this.$router.push({name: 'Main', query:{scr: this.$route.query.scr}});
        })
        .catch((err) => {
            console.log(err)
        })
      }
    }
}
</script>

<style>

</style>