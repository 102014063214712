<template>
  <div class="under-wrapper">
    <div v-show="!loading" class="custom-container" style="background: transparent;">
      <div class="empty-tab">
        <div style="width: 100%;height: 80%;border-radius: 30px;overflow: hidden;padding: 0;margin-top: 100px">
          <lottie-player src="https://lottie.host/647cc137-6bbb-4871-9358-d1c3be8545c8/kuvptnlWg2.json" 
                         background="transparent" speed="1" style="width: 100%" loop autoplay>
          </lottie-player>
        </div>

        <h2 class="coomingsoon-title text-dark">Under Construction</h2>
        <h5 class="mt-3">We're currently working on something awesome!</h5>

        <router-link :to="{name: 'Main', query: { scr: this.$route.query.scr}}" class="btn login-btn w-100 mt-3 mb-3" role="button">
            Back to Home
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'UnderConstructionView',
    data(){
      return{
        loading: false,
        servers: '',
      }
    },
    mounted(){
      const decode = atob(this.$route.query.scr);
      const arrData = decode.split(";");
      this.servers = 'http://' + arrData[0] + '/api/web';

      axios.get( this.servers + '/cart/' + this.$route.query.scr)
      .then((res) => {
          if(res.data.is_submitted) this.$router.push({name: 'Submitted', query:{scr: this.$route.query.scr}});
      })
      
      this.loadAssets()
    },
    methods:{
      loadAssets(){
        this.loading = true;

        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    }
}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/joy-kim');

.coomingsoon-title{
    font-family: 'Joy Kim', sans-serif;
    font-size: 21pt;
}

.under-wrapper{
    height: 100vh;
    background-color: #fffaf6;
}
</style>