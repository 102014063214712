import { createRouter, createWebHistory } from 'vue-router'
import BranchView from '../views/BranchView.vue'
import MainView from '../views/MainView.vue'
import StoreView from '../views/StoreView.vue'
// import OffersView from '../views/OffersView.vue'
import ProfileView from '../views/ProfileView.vue'
import CategoryView from '../views/CategoryView.vue'
import VoucherView from '../views/VoucherView.vue'
import ProfileSettingView from '../views/ProfileSettingView.vue'
import WhistlistView from '../views/WhistlistView.vue'
import ManagePaymentView from '../views/ManagePaymentView.vue'
import NotificationView from '../views/NotificationView.vue'
import CartView from '../views/CartView.vue'
import CouponView from '../views/CouponsView.vue'
import OrderHistoryView from '../views/OrderHistoryView.vue'
import OtherSettingView from '../views/OtherSettingView.vue'
import NotificationSettingView from '../views/NotificationSettingView.vue'
import LoginView from '../views/LoginView.vue'
import FoodView from '@/views/FoodView.vue'
import PageView from '../views/PageView.vue'
import EmptyCartView from '@/views/EmptyCartView.vue'
import LandingViewVue from '@/views/LandingView.vue'
import RegisterViewVue from '@/views/RegisterView.vue'
import CategoryDetailView from '@/views/CategoryDetailView.vue'
import RecomendedViewVue from '@/views/RecomendedView.vue'
import UnderConstructionViewVue from '../views/UnderConstructionView.vue';
import SubmittedView from '../views/SubmittedView.vue'

const routes = [
  {path: '/', name: 'Home', component: LandingViewVue},
  {path: '/branch', name: 'Branch', component : BranchView},
  {path: '/main', name: 'Main', component: MainView},
  {path: '/store', name: 'Store', component: StoreView},
  {path: '/offers', name: 'Offer', component: UnderConstructionViewVue},
  {path: '/profile', name: 'Profile', component: ProfileView},
  {path: '/categorie', name: 'Category', component: CategoryView},
  {path: '/category', name: 'CategoryDetail', component: CategoryDetailView},
  {path: '/recomended', name: 'Recomended', component: RecomendedViewVue},
  {path: '/voucher', name: 'Voucher', component: VoucherView},
  {path: '/coupon', name: 'Coupon', component: CouponView},
  {path: '/notification', name: 'Notification', component: NotificationView},
  {path: '/cart', name: 'Cart', component: CartView},
  {path: '/cart-empty', name: 'CartEmpty', component: EmptyCartView},
  {path: '/login', name: 'Login', component: LoginView},
  {path: '/register', name: 'Register', component: RegisterViewVue},
  {path: '/food', name: 'Food', component: FoodView},
  {path: '/profile-setting', name: 'Profile-Setting', component: ProfileSettingView},
  {path: '/whistlist', name: 'Whistlist', component: WhistlistView},
  {path: '/manage-payment', name: 'Manage-Payment', component: ManagePaymentView},
  {path: '/order-history', name: 'Order-History', component: OrderHistoryView},
  {path: '/other-setting', name: 'Other-Setting', component: OtherSettingView},
  {path: '/notification-setting', name: 'Notification-Setting', component: NotificationSettingView},
  {path: '/promotion', name: 'Promotion', component: PageView},
  {path: '/submitted', name: 'Submitted', component: SubmittedView},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
