<template>
    <div class="restaurant-food-loaders">
      <div class="wrapper">
          <div class="circle"></div>
          <div class="box"></div>
          <div class="line-1"></div>
          <div class="line-2"></div>
          <div class="line-3"></div>
          <div class="line-4"></div>
          <div class="line-5"></div>
      </div>
      </div>
  </template>
  
  <script>
  export default {
      name: 'RestaurantFoodLoader'
  }
  </script>
  
  <style>
  @media only screen and (max-width: 800px){
    .restaurant-food-loaders {
        position: relative;
        width: 125%;
        height: 140px;
        padding: 15px;
        background-color: #f7f7f7;
        overflow: hidden;
        margin: 0px 5px;
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
                box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
        margin-top: 20px;
    }
  }

  @media (min-width: 800px){
    .restaurant-food-loaders {
        position: relative;
        width: 100%;
        height: 140px;
        padding: 15px;
        background-color: #f7f7f7;
        overflow: hidden;
        margin: 0px 5px;
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
                box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
        margin-top: 20px;
    }
  }
  
  .restaurant-food-loaders:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(120deg, rgba(227, 227, 227, 0) 0%, rgba(227, 227, 227, 0) 40%, rgb(255, 255, 255) 50%, rgba(227, 227, 227, 0) 60%, rgba(227, 227, 227, 0) 100%);
    animation: gradient-animation_2 1s linear infinite;
  }
  
  .restaurant-food-loaders .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .restaurant-food-loaders .wrapper > div {
    background-color: #ececec;
    background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
    background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
    background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  }
  
  .restaurant-food-loaders .button {
    display: inline-block;
    height: 32px;
    width: 75px;
  }

  @media only screen and (max-width: 800px){
    .restaurant-food-loaders .circle {
        position: absolute;
        top: -5px;
        width: 75px;
        height: 75px;
        right: 1%;
        border-radius: 50%;
    }

    .restaurant-food-loaders .box {
        padding: 0;
        position: absolute;
        top: 80px;
        width: 18%;
        height: 30px;
        right: 10px;
        border-radius: 5px;
    }
  }

  @media (min-width: 800px){
    .restaurant-food-loaders .circle {
        position: absolute;
        top: -5px;
        width: 75px;
        height: 75px;
        right: 4%;
        border-radius: 50%;
    }

    .restaurant-food-loaders .box {
        padding: 0;
        position: absolute;
        top: 80px;
        width: 15%;
        height: 30px;
        right: 3%;
        border-radius: 5px;
    }
  }

  
  .restaurant-food-loaders .line-1 {
    position: absolute;
    top: 11px;
    left: 20%;
    height: 7px;
    width: 35%;
    border-radius: 30px;
  }
  
  .restaurant-food-loaders .line-2 {
    position: absolute;
    top: 34px;
    left: 0;
    height: 7px;
    width: 30%;
    border-radius: 30px;
  }
  
  .restaurant-food-loaders .line-3 {
    position: absolute;
    top: 57px;
    left: 24%;
    height: 7px;
    width: 28%;
    border-radius: 30px;
  }

  .restaurant-food-loaders .line-4 {
    position: absolute;
    top: 80px;
    left: 0px;
    height: 7px;
    width: 75%;
    border-radius: 30px;
  }

  .restaurant-food-loaders .line-5 {
    position: absolute;
    top: 103px;
    left: 0px;
    height: 7px;
    width: 75%;
    border-radius: 30px;
  }
  
  @keyframes gradient-animation_2 {
    0% {
      transform: translateX(-100%);
    }
  
    100% {
      transform: translateX(100%);
    }
  }
  </style>