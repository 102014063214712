<template>
  <submitted-view v-show="cart.is_submitted" :message="message"></submitted-view>

  <div v-show="!cart.is_submitted" class="foodpage-wrapper">
    <header class="section-t-space">
      <div class="custom-container">
        <div class="header-panel">
          <router-link :to="{name: 'Main', query: {scr: this.$route.query.scr}}">
            <i class="ri-arrow-left-s-line"></i>
          </router-link>
          <h2>{{ branch.company_name }}</h2>
        </div>
      </div>
    </header>
    <!-- header end -->

    <!-- Add Cart section start -->
    <section>
      <div class="custom-container" >
        <h3 class="fw-semibold dark-text">Food Items</h3>
        <div v-for="carts in cart.cart_item" :key="carts.id">
          <div class="horizontal-product-box mt-3" :class="{'border border-danger' : carts.limit === 0, 'bg-warm' : carts.is_submitted}">
            <div class="product-img">
              <div v-show="carts.limit === 0" class="cart-limit"><h6>Sold Out</h6></div>
              <img class="img-fluid img" :src="getImageUrl(carts.image)" alt="rp1" />
            </div>
            <div class="product-content">
              <h4 class="mb-2 position-relative" :class="{'text-danger' : carts.limit === 0}" 
                  style="width: 95%;font-weight: bold;" v-text="carts.nama"></h4>

              <div v-for="opt in carts.options" :key="opt.id" class="d-flex justify-content-center" style="width: 70%;">
                <span style="font-size: 9pt;font-weight: bold;">{{ opt.nama }}</span>
              </div>

              <span style="font-size: 9pt; margin-left: 10px;color: #d43c00" v-show="carts.limit > 0 && carts.limit <= 10">Remaining food stock {{ carts.limit }} left!</span>
              <span style="font-size: 9pt; margin-left: 10px;color: #d43c00" v-show="carts.limit == 0">Sorry, Item out of stock!</span>
              <h3 v-show="carts.is_submitted" class="text-start text-danger" style="font-size: 10pt;">Item submitted to kitchen</h3>

              <div v-show="carts.is_submitted" style="width: 100%;height: 30px;background: transparent;"></div>
              <div v-show="!carts.is_submitted" class="plus-minus position-relative" style="width: 90px;border: 2px solid #603939;" :class="{'mt-3' : carts.options.length <= 0}">
                <i class="ri-subtract-line sub" @click="subQty(carts.id, carts.qorder)"></i>
                  <input type="number" :value="carts.qorder" min="1" max="10" />
                <i class="ri-add-line add" @click="addQty(carts.id, carts.qorder)"></i>
              </div>

              <a class="red-symbol" v-show="!carts.is_submitted" @click="deleteItem(carts.id, carts.kode)" style="cursor: pointer;"><i class="ri-delete-bin-fill"></i></a>
              <span class="red-symbol" v-show="carts.is_submitted" style="font-weight: bolder;font-size: 13pt;">{{ carts.qorder }}</span>
              <h3 class="product-price" :class="{'text-danger' : carts.limit === 0}" >Rp. {{formatPrice(parseFloat(carts.harga)) }}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Add Cart section end -->

    <!-- Bill details section start -->
    <section class="bill-details">
      <div class="custom-container">
        <h3 class="fw-semibold dark-text">Bill Details</h3>
        <div class="total-detail mt-3">
          <div class="sub-total">
            <h5>Sub Total</h5>
            <h5 class="fw-semibold text-danger" v-text="'Rp. ' + formatPrice(parseFloat(cart.total))"></h5>
          </div>
          <div class="sub-total mt-2"  v-if="parseFloat(cart.tcharge) > 0">
            <h5>Service Charge</h5>
            <h5 class="fw-semibold text-danger" v-text="'Rp. ' + formatPrice(parseFloat(cart.tcharge))"></h5>
          </div>
          <div class="sub-total mt-2" v-if="parseFloat(cart.tdisc) > 0">
            <h5>Discount</h5>
            <h5 class="fw-semibold" v-text="'Rp. ' + formatPrice(cart.tdisc)"></h5>
          </div>
          <div class="sub-total mt-2 pb-3">
            <h5>Tax</h5>
            <h5 class="fw-semibold" v-text="'Rp. ' + formatPrice(parseFloat(cart.ttax))"></h5>
          </div>
          <div class="grand-total">
            <h5 class="fw-semibold">Grand Total</h5>
            <h5 class="fw-semibold amount" v-text="'Rp. ' + formatPrice(cart.grand)"></h5>
          </div>
          <img class="dots-design" src="/assets/images/svg/dots-design.svg" alt="dots" />
        </div>
      </div>
    </section>
    <!-- Bill details section end -->

    <!-- delivery address section start -->
    <section class="delivery address section-lg-b-space">
      <div class="custom-container">
        <h3 class="fw-semibold dark-text">Customer Detail</h3>
        <div class="cart-add-box mt-3 mb-5">
          <div class="add-img">
            <img class="img" style="width: 30px;height: 30px;" src="/assets/images/icons/profile.png" alt="rp1" />
          </div>
          <div class="add-content">
            <h5 class="fw-semibold dark-text text-start">Deliver to : {{ branch.table }}</h5>
            <h6 class="address light-text mt-1 text-start">{{ branch.company_name }}</h6>
          </div>
          <!-- <div class="add-content">
            <h5 class="fw-semibold dark-text text-start">Jumlah Orang : <span class="text-danger">*</span></h5>
            <div class="plus-minus align-items-center" style="width: 110px;border: 2px solid #603939;border-radius: 15px;">
              <i class="ri-subtract-line" @click="subjuml"></i>
                <input type="number" name="qty" id="qty" min="0" max="50" v-model="jumorang" 
                       style="border: 1px solid #fff;text-align: center;"/>
              <i class="ri-add-line" @click="jumorang++"></i>
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <!-- delivery address section end -->

    <!-- pay popup start -->
    <div class="food-pay-popup">
      <div class="price-items">
        <h3 v-text="'Rp. ' + formatPrice(parseFloat(cart.grand))"></h3>
        <h6 v-text="qty + ' item Added'"></h6>
      </div>
      <button class="btn wood-btn pay-btn mt-0" @click="placeorder">Place Order</button>
    </div>

    
    <form ref="form">
      <input type="text" name="to_name" :value="branch.company_name" hidden>
      <input type="text" name="table" :value="branch.table" hidden>
      <input type="text" name="address_to" value="galihgustiawan24@gmail.com" hidden>
      <input type="text" name="message" v-model="email_body" hidden>
    </form>

  </div>
</template>

<script>
import SubmittedView from './SubmittedView.vue';
import axios from 'axios';
import emailjs from 'emailjs-com';

export default {
    name: 'CartView',
    components: {
      SubmittedView,
    },
    data(){
      return {
        cart: [ ],
        maxProcessing: 0,
        quantity: 0,
        opt: [],
        qty: 0,
        jumorang: 1,
        carts: {
          foods: [],
        },
        foods: {
            kode: '',
            harga: 0,
            service_charge: 0,
            discount: 0,
            tax: 0,
            qty: 0,
            opt2: '',
            opt1: '',
        },
        opt1: {
            kode: "",
            harga: 0,
            service_charge: 0,
            discount: 0,
            tax: 0,
            qty: 0,
            opt2: '',
            opt1: '',
        },
        opt2: {
            kode: "",
            harga: 0,
            service_charge: 0,
            discount: 0,
            tax: 0,
            qty: 0,
            opt2: '',
            opt1: '',
        },
        branch: [],
        mainfoods: [],
        options: [],
        email_body: '',
        message: '',
        servers: '',
      }
    },
    mounted(){
        const decode = atob(this.$route.query.scr);
        const arrData = decode.split(";");
        this.servers = 'http://' + arrData[0] + '/api/web';
        
        this.getCartItem();
        this.getBranch();
    },
    methods: {
      subjuml(){
        this.jumorang--
        if(this.jumorang <= 1) this.jumorang = 1;
      },
      async getCartItem(){
        try{
          let { data } = await axios.get( this.servers + '/cart/' + this.$route.query.scr);
          if(data.message === 'Cart Empty'){
            this.$router.push({name: 'CartEmpty', query: {scr: this.$route.query.scr}})
          } else {
            this.cart = data;
            this.opt  = data.cart_item;
            var i = 1;
            this.opt.forEach(element => {
                this.qty += element.qorder
                if(element.is_submitted == false) this.email_body += '(' + String(i) + ')' + '. ' + element.nama + ', Qty: ' + element.qorder + '. ';
                i++;
            });
          }
        } catch(e){console.log(e)}
      },
      async getBranch(){
        try{
          let { data } = await axios.get(this.servers + '/profile/' + this.$route.query.scr);
          this.branch = data;
        } catch(e){console.log(e)}
      },
      getImageUrl(filename){
        let name = String(filename).replace("JPG", "png");
        return '/assets/images/food/tamani/' + name;
      },
      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      async deleteItem(id, kode){
        await axios.delete(this.servers + '/cart/' + this.$route.query.scr, {
          headers: { "Content-Type": "application/json" },
          data: { id: id, kode: kode }
        }).then((res) => {
            if(res.data.message === 'Cart Empty') { 
              this.$router.push({name: 'CartEmpty', query: {scr: this.$route.query.scr}})
            } else {
              this.qty = 0;
              this.cart = res.data
              this.opt  = res.data.cart_item;
              this.opt.forEach(element => {
                  this.qty += element.qorder
              });
            }
        }).catch((e) => {throw e;});
      },
      async placeorder(){
        try{
          let { data } = await axios.post(this.servers + '/order/' + this.$route.query.scr);
          this.message = data.message;
          this.getCartItem();
        } catch(e){console.log(e)}

        if(this.email_body !== ''){
            try {
              emailjs.sendForm('service_pztgbf8', 'template_fpdg72d', this.$refs.form, 'mmIOUzpRcPQ2eAO1K')
              .then((result) => {
                  console.log('SUCCESS!', result.text);
              }, (error) => {
                  console.log('FAILED...', error.text);
              });
            } catch(e){console.log({e})}
        }
      },
      async addQty(id, qty) {
        if(this.maxProcessing === 1) return;
        this.maxProcessing = 1;
        const quantity = parseInt(qty) + 1;
        let mainfoods = this.opt.find(element => element.id === id);
        this.foods.kode = mainfoods.kode;
        this.foods.harga = mainfoods.nharga;
        this.foods.qty = quantity;
        this.foods.service_charge = mainfoods.ncharge;
        this.foods.discount = mainfoods.ndisc;
        this.foods.tax = mainfoods.ntax;

        let options = mainfoods.options;
        var len = options.length;
        if(len === 1){
          this.foods.opt1 =options[0].kode; 
          this.opt1.kode = options[0].kode;
          this.opt1.harga = options[0].nharga;
          this.opt1.service_charge = options[0].ncharge;
          this.opt1.discount = options[0].ndisc;
          this.opt1.tax = options[0].ntax;
          this.opt1.qty = quantity;
          this.opt1.opt2 = '';
          this.opt1.opt1 = '';
        } else if(len === 2){
          this.foods.opt1 =options[0].kode; 
          this.opt1.kode = options[0].kode;
          this.opt1.harga = options[0].nharga;
          this.opt1.service_charge = options[0].ncharge;
          this.opt1.discount = options[0].ndisc;
          this.opt1.tax = options[0].ntax;
          this.opt1.qty = quantity;
          this.opt1.opt2 = '';
          this.opt1.opt1 = '';

          this.foods.opt2 =options[1].kode;
          this.opt2.kode = options[1].kode;
          this.opt2.harga = options[1].nharga;
          this.opt2.service_charge = options[1].ncharge;
          this.opt2.discount = options[1].ndisc;
          this.opt2.tax = options[1].ntax;
          this.opt2.qty = quantity;
          this.opt2.opt2 = '';
          this.opt2.opt1 = '';
        }

        this.carts.foods.push(this.foods);
        if(this.opt1.kode !== '') this.carts.foods.push(this.opt1);
        if (this.opt2.kode !== '') this.carts.foods.push(this.opt2);

        try {
          let { data } = await axios.post(this.servers + '/update_cart/' + this.$route.query.scr, this.carts)
          this.qty = 0;
          this.cart = data;
          this.opt  = data.cart_item;
          this.opt.forEach(element => {
              this.qty += element.qorder
          });
        } catch(e){console.log(e)}

      this.cleanObject(this.foods);
      this.cleanObject(this.opt1);
      this.cleanObject(this.opt2);
      this.maxProcessing = 0;
    },
    async subQty(id, qty) {
        if(this.maxProcessing === 1) return;
        this.maxProcessing = 1;
        var quantity = parseInt(qty) <= 1 ? 1 : parseInt(qty) - 1;
        let mainfoods = this.opt.find(element => element.id === id);
        this.foods.kode = mainfoods.kode;
        this.foods.harga = mainfoods.nharga;
        this.foods.qty = quantity;
        this.foods.service_charge = mainfoods.ncharge;
        this.foods.discount = mainfoods.ndisc;
        this.foods.tax = mainfoods.ntax;
        
        let options = mainfoods.options;
        var len = options.length;
        if(len === 1){
          this.foods.opt1 =options[0].kode; 
          this.opt1.kode = options[0].kode;
          this.opt1.harga = options[0].nharga;
          this.opt1.service_charge = options[0].ncharge;
          this.opt1.discount = options[0].ndisc;
          this.opt1.tax = options[0].ntax;
          this.opt1.qty = quantity;
          this.opt1.opt2 = '';
          this.opt1.opt1 = '';
        } else if(len === 2){
          this.foods.opt1 =options[0].kode; 
          this.opt1.kode = options[0].kode;
          this.opt1.harga = options[0].nharga;
          this.opt1.service_charge = options[0].ncharge;
          this.opt1.discount = options[0].ndisc;
          this.opt1.tax = options[0].ntax;
          this.opt1.qty = quantity;
          this.opt1.opt2 = '';
          this.opt1.opt1 = '';

          this.foods.opt2 =options[1].kode;
          this.opt2.kode = options[1].kode;
          this.opt2.harga = options[1].nharga;
          this.opt2.service_charge = options[1].ncharge;
          this.opt2.discount = options[1].ndisc;
          this.opt2.tax = options[1].ntax;
          this.opt2.qty = quantity;
          this.opt2.opt2 = '';
          this.opt2.opt1 = '';
        }

        this.carts.foods.push(this.foods);
        if(this.opt1.kode !== '') this.carts.foods.push(this.opt1);
        if(this.opt2.kode !== '') this.carts.foods.push(this.opt2);

        try {
          let { data } = await axios.post(this.servers + '/update_cart/' + this.$route.query.scr, this.carts)
          this.qty = 0;
          this.cart = data
          this.opt  = data.cart_item;
          this.opt.forEach(element => {
              this.qty += element.qorder
          });
        } catch(e){console.log(e)}

      this.cleanObject(this.foods);
      this.cleanObject(this.opt1);
      this.cleanObject(this.opt2);
      this.maxProcessing = 0;
    },
    cleanObject(objVal){
      Object.keys(objVal).forEach(key => {
          if (typeof objVal[key] === 'string' || objVal[key] instanceof String) objVal[key] = '';
          else if (typeof objVal[key] === 'number' || objVal[key] instanceof Number) objVal[key] = 0;
      });
    },
  }
}
</script>

<style>
.bg-warm{
  background-color: #eaffa9;
  border: 2px solid #870000;
}

.pay-popup{
    z-index: 1000;
}

.red-symbol {
    color: red;
}

.cart-limit{
  position: absolute;
  width: 80px;
  height: 80px;
  z-index: 1000;
  background-color: #00000080;
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

.cart-limit h6{
  color: #fff;
  margin-top: 40%;
  font-size: 12pt;
  font-weight: bold;
}
</style>