<template>
  <div>
        <!-- header start -->
        <header>
            <div v-if="loading">
                <RestaurantImageLoaderVue />
            </div>
        
            <div v-if="!loading" class="head-panel-lg">
                <img class="restaurant-bg" src="/assets/images/background/restaurant-bg.png" alt="" />
                    <div class="custom-container">
                </div>
            </div>
        </header>
        <!-- header end -->

        <!-- Restaurant details section start -->
        <section class="restaurant-detail-container">
        <div class="custom-container">
            <div v-if="loading">
                <RestaurantDetailLoaderVue />
            </div>

            <div v-if="!loading" class="restaurant-details-card">
            <div class="restaurant-head">
                <div class="name">
                <img class="img-fluid restaurant-logo" src="/assets/images/svg/tamani.png" alt="starbucks-logo" />
                <div class="d-block ms-2">
                    <h3>Tamani Caffe Tomang</h3>
                    <h6 style="margin-left: -120px;">Caffe</h6>
                </div>
                </div>
                <div class="option">
                <a href="#">
                    <i class="ri-share-line share"></i>
                </a>

                <a href="#">
                    <i class="toggle-heart ri-heart-3-fill heart"></i>
                </a>
                </div>
            </div>

            <div class="restaurant-details">
                <div class="location">
                <h6 class="rating-star">
                    <span class="star"><i class="ri-star-s-fill"></i></span> 4.7 (1k+ Reviews)
                </h6>
                <h6 class="pt-2 light-text"><span class="dark-text">4.0km</span> Free Delivery</h6>
                </div>
                <ul class="distance">
                <li><i class="ri-map-pin-fill theme-color"></i> 1 km</li>
                <li><i class="ri-time-fill theme-color"></i> 15 min</li>
                </ul>
            </div>
            </div>
        </div>
        </section>
        <!-- Restaurant details section end -->

        <!-- offer section start -->
        <section>
        <div class="custom-container">
            <div v-if="loading">
                <RestaurantVoucherLoaderVue />
            </div>

            <!-- <div v-if="!loading" class="swiper coupon">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                    <div class="offer-box">
                        <div class="offer-icon">
                        <img class="img-fluid offer" src="/assets/images/icons/Offer-Discount.png" alt="offer" />
                        </div>
                        <div class="offer-content">
                        <h5>50% OFF upto & $25</h5>
                        <h6 class="light-text">Use Code MEFRGD124</h6>
                        </div>
                    </div>
                    </div>
                    <div class="swiper-slide">
                    <div class="offer-box">
                        <div class="offer-icon">
                        <img class="img-fluid offer" src="/assets/images/icons/Offer-Discount.png" alt="offer" />
                        </div>
                        <div class="offer-content">
                        <h5>50% OFF upto & $25</h5>
                        <h6 class="light-text">Use Code MEFRGD124</h6>
                        </div>
                    </div>
                    </div>
                    <div class="swiper-slide">
                    <div class="offer-box">
                        <div class="offer-icon">
                        <img class="img-fluid offer" src="/assets/images/icons/Offer-Discount.png" alt="offer" />
                        </div>
                        <div class="offer-content">
                        <h5>50% OFF upto & $25</h5>
                        <h6 class="light-text">Use Code MEFRGD124</h6>
                        </div>
                    </div>
                    </div>
                </div>
            </div> -->

        </div>
        </section>
        <!-- offer section end -->

        <!-- filter section start  -->
        <section class="food-filter">
        <div class="custom-container">
            <ul class="food-symbol">
            <li>
                <a href="#" class="food-types">
                <img class="img-fluid img" src="/assets/images/icons/promotion.svg" alt="veg" />
                <h6>Promotion</h6>
                <i class="ri-close-line close"></i>
                </a>
            </li>
            <li>
                <a href="#" class="food-types active">
                <img class="img-fluid img" src="/assets/images/icons/new-icon.svg" alt="non-veg" />
                <h6>New</h6>
                <i class="ri-close-line close"></i>
                </a>
            </li>
            <li>
                <a href="#" class="food-types">
                <i class="ri-award-fill award"></i>
                <h6>Favourite</h6>
                <i class="ri-close-line close"></i>
                </a>
            </li>
            </ul>
        </div>
        </section>
        <!-- filter section end  -->

        <!-- food section start -->
        <section class="food-list-section section-b-space">
        <div class="custom-container">
            <div class="list-box">
            <h3 class="fw-semibold dark-text">Recommended</h3>
            <div class="accordion food-accordion" id="accordionPanelsStayOpenaccordion1">
                <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="collapseOne">Featured Food (04)</button>
                </h2>
                
                <div v-if="loading">
                    <RestaurantFoodLoaderVue />
                    <RestaurantFoodLoaderVue />
                    <RestaurantFoodLoaderVue />
                    <RestaurantFoodLoaderVue />
                    <RestaurantFoodLoaderVue />
                </div>


                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample ">
                    <div class="accordion-body" v-for="food in foods" :key="food.id">
                        <div class="product-box2">
                            <div class="product-content">
                            <img v-show="food.quantity > 0" style="width: 32px" src="/assets/images/icons/instock.png" alt="non-veg" />
                            <img v-show="food.quantity === 0" style="width: 32px" src="/assets/images/icons/outstock.png" alt="non-veg" />
                            <h5 class="product-name">{{ food.name }}</h5>
                            <div class="d-flex align-items-center gap-1">
                                <!-- <ul class="rating-stars">
                                <li><i class="ri-star-fill stars"></i></li>
                                <li><i class="ri-star-fill stars"></i></li>
                                <li><i class="ri-star-fill stars"></i></li>
                                <li><i class="ri-star-fill stars"></i></li>
                                <li><i class="ri-star-fill stars"></i></li>
                                </ul>
                                <h5 class="dark-text">1k+ Rating</h5> -->
                                <h5 class="dark-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sit amet vulputate mauris. Donec eleifend leo ac nisi feugiat, ut vestibulum odio iaculis.</h5>
                            </div>
                            <div class="product-price mt-2">
                                <h6 class="fw-semibold"><span>Rp. {{ formatPrice(food.price) }}</span></h6>
                            </div>
                            <p class="mb-0 mt-2 pt-2">{{ food.description }}</p>
                            </div>
                            <div class="product-img">
                            
                                <router-link :to="{name: 'Food', query:{id: food.id, scr: this.$route.query.scr}}" v-show="food.quantity > 0">
                                    <img class="img-fluid img" :src="getImageUrl(food.image)" :alt="food.image" />
                                </router-link>

                                <div v-show="food.quantity === 0">
                                    <img class="img-fluid img" :src="getImageUrl(food.image)" :alt="food.image" />
                                    <div style="position: absolute;top:0;z-index:1000;width: 100%; height:  90%;background-color: rgba(73, 73, 73, 0.558);border-radius: 20px;"></div>
                                </div>

                                <router-link :to="{name: 'Food', query: { id: food.id, scr: this.$route.query.scr}}">
                                    <div class="add-btn" v-show="food.quantity > 0">
                                        <a class="btn btn-outline" ><i class="ri-add-line"></i> ADD</a>
                                    </div>
                                </router-link>

                                <div style="font-weight: bold;" v-show="food.quantity === 0">
                                    Sold Out
                                </div>

                                <div class="plus-minus">
                                    <i class="ri-subtract-line sub"></i>
                                    <input type="number" value="1" min="1" max="10" />
                                    <i class="ri-add-line add"></i>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <!-- food section end -->

        <!-- panel-space start -->
        <section class="panel-space"></section>
        <!-- panel-space end -->

        <!-- filter offcanvas start -->
        <div class="modal filter-modal" id="add-product" tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
            <div class="custom-container">
                <div class="filter-header section-t-space">
                <h1 class="title">Custom Food</h1>
                <a href="#" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
                </div>
                <div class="filter-body section-t-space">
                <div class="horizontal-product-box">
                    <div class="product-img">
                    <img class="img-fluid img" src="/assets/images/product/rp1.png" alt="rp1" />
                    </div>
                    <div class="product-content">
                    <h5>Mexican Shred Beef</h5>
                    <h6>Hot beef stick</h6>
                    <div class="plus-minus">
                        <i class="ri-subtract-line sub"></i>
                        <input type="number" value="1" min="1" max="10" />
                        <i class="ri-add-line add"></i>
                    </div>
                    <img class="red-symbol" src="/assets/images/svg/nonveg.svg" alt="non-veg" />
                    <h3 class="product-price">$40</h3>
                    </div>
                </div>

                <div class="filter-box section-t-space">
                    <h3 class="fw-semibold dark-text">Choose Size</h3>

                    <ul class="filter-list mt-3">
                    <li>
                        <h5 class="product-size">Size S</h5>
                        <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$18</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck1" />
                        </div>
                    </li>
                    <li>
                        <h5 class="product-size">Size M</h5>
                        <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$20</label>
                        <input class="form-check-input" type="checkbox" id="reverseCheck2" checked />
                        </div>
                    </li>
                    <li>
                        <h5 class="product-size">Size L</h5>
                        <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$22</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck3" />
                        </div>
                    </li>
                    </ul>
                </div>

                <div class="body-title section-t-space">
                    <h3 class="fw-semibold dark-text">Choose Size</h3>

                    <ul class="filter-list mt-3">
                    <li>
                        <h5 class="product-size">Chili sauce</h5>
                        <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$18</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck4" />
                        </div>
                    </li>
                    <li>
                        <h5 class="product-size">Mustard</h5>
                        <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$20</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck5" checked />
                        </div>
                    </li>
                    <li>
                        <h5 class="product-size">Special dipping sauce</h5>
                        <div class="form-check product-price">
                        <label class="form-check-label" for="reverseCheck1">$22</label>
                        <input class="form-check-input" type="checkbox" value="" id="reverseCheck6" />
                        </div>
                    </li>
                    </ul>
                </div>
                <div class="body-title d-flex justify-content-between section-t-space section-b-space">
                    <h3 class="fw-semibold dark-text d-flex align-items-center">Grand Total</h3>
                    <h2 class="theme-color">$40</h2>
                </div>
                </div>
                <div class="filter-footer">
                <a href="#" class="btn theme-btn apply-btn w-100" data-bs-dismiss="modal" data-bs-toggle="offcanvas">Apply</a>
                </div>
            </div>
            </div>
        </div>
        </div>
        <!-- filter offcanvas end -->

        <!-- product-popup offcanvas start -->
        <div class="offcanvas fade offcanvas-bottom product-detail-popup" tabindex="-1" id="product-popup">
        <div class="product-box-modal ratio_40">
            <div class="product-img">
            <img class="bg-img" src="/assets/images/product/rp1.png" alt="rp1" />
            </div>
            <div class="product-content">
            <div class="product-info">
                <img class="img" src="/assets/images/svg/nonveg.svg" alt="non-veg" />
                <h5 class="product-name">Mexican Shred Beef</h5>
                <div class="d-flex align-items-center gap-2 mb-2">
                <ul class="rating-stars">
                    <li><i class="ri-star-fill stars"></i></li>
                    <li><i class="ri-star-fill stars"></i></li>
                    <li><i class="ri-star-fill stars"></i></li>
                    <li><i class="ri-star-fill stars"></i></li>
                    <li><i class="ri-star-fill stars"></i></li>
                </ul>
                <h5 class="dark-text">1k+ Rating</h5>
                </div>
                <div class="product-price">
                <h6 class="fw-semibold"></h6>
                </div>
            </div>
            <div class="plus-minus">
                <i class="ri-subtract-line sub"></i>
                <input type="number" value="1" min="1" max="10" />
                <i class="ri-add-line add"></i>
            </div>
            </div>
            <p>A super easy Mexican style shredded beef cooked in a slow cooker with smoky chipotle chilies with a hint of cocoa and cinnamon.</p>
            <ul class="ingredient-list">
            <li>2-3 pounds beef roast (such as chuck, eye of round, etc.)</li>
            <li>1 tablespoon ground chipotle chilis</li>
            <li>2 tablespoons ground ancho chilis</li>
            <li>1 teaspoon ground cumin</li>
            <li>1 tablespoon oregano</li>
            </ul>
        </div>
        </div>
        <!-- product-popup offcanvas end -->

        <!-- Navbar -->
        <NavbarVue />
  </div>
</template>

<script>
import NavbarVue from '../components/NavbarV2.vue';
import axios from 'axios';
import RestaurantFoodLoaderVue from '../components/loader/RestaurantFoodLoader.vue';
import RestaurantDetailLoaderVue from '@/components/loader/RestaurantDetailLoader.vue';
import RestaurantVoucherLoaderVue from '@/components/loader/RestaurantVoucherLoader.vue';
import RestaurantImageLoaderVue from '@/components/loader/RestaurantImageLoader.vue';

export default {
    name: 'StoreView',
    components: {
        NavbarVue,
        RestaurantFoodLoaderVue,
        RestaurantDetailLoaderVue,
        RestaurantVoucherLoaderVue,
        RestaurantImageLoaderVue
    },
    data(){
        return {
            foods: [ ],
            loading: false,
            servers: '',
        }
    },
    mounted(){
        this.loading = true;
        const decode = atob(this.$route.query.scr);
        const arrData = decode.split(";");
        this.servers = 'http://' + arrData[0] + '/api/web';
                
        axios.get( this.servers + '/cart/' + this.$route.query.scr)
        .then((res) => {
            if(res.data.is_submitted) this.$router.push({name: 'Submitted', query:{token: this.$route.query.scr}});
        });

        this.getAllFood()
    },
    methods: {
        async getAllFood(){
            this.loading = true;
            try {
                let { data } = await axios.get(this.servers + '/menus/' + this.$route.query.scr);
                this.foods = data;
                console.log(data);
                this.loading = false;
            } catch (e) { 
                this.loading = true;
            }
        },
        getImageUrl(filename){
            let name = filename.replace("JPG", "png");
            return '/assets/images/food/tamani/' + name;
        },
        formatPrice(value) {
          let val = (value/1).toFixed(2).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
    }
}
</script>

<style>
.restaurant-bg{
    width:100%;
}
.restaurant-detail-container{
    margin-top:20%;
}
.head-panel-lg {
  position: relative;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 184px;

}
.head-panel-lg .panel {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 30px;
  width: calc(100% - 40px);
}
.header-panel-lg .panel a {
  color: rgb(225, 225, 225);
  background: rgba(0, 0, 0, 0.25);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  border: 1px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 0;
  position: relative;
}
.head-panel-lg .panel a:after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100%;
  padding: 1px;
  background: linear-gradient(128.66deg, rgba(var(--white), 1) -1.63%, rgba(var(--white), 0) 92.16%);
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(var(--white), 1))) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(var(--white), 1)));
  -webkit-mask: linear-gradient(rgba(var(--white), 1) 0 0) content-box, linear-gradient(rgba(var(--white), 1) 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: 1;
}

.restaurant-details-card {
  position: relative;
  background: #fff9f4;
  border: 3px solid #603939;
  -webkit-box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  padding: 0 12px;
  margin-top: -80px;
  z-index: 1;
}
.restaurant-details-card .restaurant-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  padding: 12px 0;
}
.restaurant-details-card .restaurant-head .name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.restaurant-details-card .restaurant-head .name .restaurant-logo {
  width: 40px;
  height: 40px;
}
.restaurant-details-card .restaurant-head .name h3 {
  font-weight: 700;
  color: rgba(var(--dark-text), 1);
}
.restaurant-details-card .restaurant-head .name h6 {
  margin-top: 5px;
  color: rgba(var(--light-text), 1);
}
.restaurant-details-card .restaurant-head .option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  font-size: 20px;
}
.restaurant-details-card .restaurant-head .option .share {
  color: rgba(var(--dark-text), 1);
}
.restaurant-details-card .restaurant-head .option .heart {
  color: rgba(var(--error-color), 1);
}
.restaurant-details-card .restaurant-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 0;
}
.restaurant-details-card .restaurant-details .location {
  width: 60%;
  border-right: 1px solid rgba(var(--dashed-line), 1);
  margin-right: calc(10px + 10 * (100vw - 320px) / 1600);
  padding-right: calc(10px + 10 * (100vw - 320px) / 1600);
}
[dir=rtl] .restaurant-details-card .restaurant-details .location {
  border-right: unset;
  margin-right: unset;
  padding-right: unset;
  border-left: 1px solid rgba(var(--dashed-line), 1);
  margin-left: calc(10px + 10 * (100vw - 320px) / 1600);
  padding-left: calc(10px + 10 * (100vw - 320px) / 1600);
}
.restaurant-details-card .restaurant-details .location .rating-star {
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  padding-bottom: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  color: rgba(var(--dark-text), 1);
}
.restaurant-details-card .restaurant-details .location .rating-star .star {
  width: 12px;
  height: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.restaurant-details-card .restaurant-details .location .rating-star .star i {
  color: rgb(255, 255, 255);
}
.restaurant-details-card .restaurant-details .distance {
  width: 40%;
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 6px;
}
.restaurant-details-card .restaurant-details .distance::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  width: 1px;
  height: 30%;
  border-left: 1px dashed rgba(var(--theme-color), 1);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
}
[dir=rtl] .restaurant-details-card .restaurant-details .distance::after {
  left: unset;
  right: 6px;
  border-left: unset;
  border-right: 1px dashed rgba(var(--theme-color), 1);
}
.restaurant-details-card .restaurant-details .distance li {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .restaurant-details-card .restaurant-details .distance li i {
  float: right;
}

</style>