<template>
  <div>
    <section class="section-t-space">
      <div class="custom-container">
        <div class="row gy-3">
          <div class="col-12">
            <div class="vertical-product-box order-box">
              <div class="vertical-box-img">
                <img class="img-fluid img" src="assets/images/product/vp1.png" alt="vp1" />
              </div>

              <div class="vertical-box-details">
                <div class="vertical-box-head">
                  <div class="restaurant">
                    <h5 class="dark-text">Ben & Floren</h5>
                    <h5 class="theme-color">$10</h5>
                  </div>
                  <h6 class="food-items mb-2">Chicken quesadilla, avocado...</h6>
                </div>
                <div class="reorder">
                  <h6 class="rating-star">
                    <ul class="timing">
                      <li>
                        <span class="star"><i class="ri-star-s-fill"></i></span> 3.7
                      </li>
                      <li>Today</li>
                    </ul>
                    <a href="#" class="btn theme-btn order mt-0" role="button">Reorder</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="vertical-product-box order-box">
              <div class="vertical-box-img">
                <img class="img-fluid img" src="assets/images/product/vp2.png" alt="vp2" />
              </div>

              <div class="vertical-box-details">
                <div class="vertical-box-head">
                  <div class="restaurant">
                    <h5 class="dark-text">242 Pizza</h5>
                    <h5 class="theme-color">$15.34</h5>
                  </div>
                  <h6 class="food-items mb-2">Chicken quesadilla, avocado...</h6>
                </div>
                <div class="reorder">
                  <h6 class="rating-star">
                    <ul class="timing">
                      <li>
                        <span class="star"><i class="ri-star-s-fill"></i></span> 3.7
                      </li>
                      <li>Today</li>
                    </ul>
                    <a href="#" class="btn theme-btn order mt-0" role="button">Reorder</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="vertical-product-box order-box">
              <div class="vertical-box-img">
                <img class="img-fluid img" src="assets/images/product/vp3.png" alt="vp3" />
              </div>

              <div class="vertical-box-details">
                <div class="vertical-box-head">
                  <div class="restaurant">
                    <h5 class="dark-text">AWS - USA</h5>
                    <h5 class="theme-color">$18.40</h5>
                  </div>
                  <h6 class="food-items mb-2">Chicken quesadilla, avocado...</h6>
                </div>
                <div class="reorder">
                  <h6 class="rating-star">
                    <ul class="timing">
                      <li>
                        <span class="star"><i class="ri-star-s-fill"></i></span> 3.7
                      </li>
                      <li>Today</li>
                    </ul>
                    <a href="#" class="btn theme-btn order mt-0" role="button">Reorder</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
    name: 'OrderHistory'
}
</script>

<style>

</style>