<template>
  <div>
    <hr class="rounded" />
  </div>
</template>

<script>
export default {
    name: 'DividerVue'
}
</script>

<style>
hr.rounded {
  border-top: 1px solid #b5b5b5;
  border-radius: 5px;
  margin: 1px;
}
</style>